import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { GenericExtender } from '@interfaces/generic-extender.interface';
import { LanguageSelectorService } from '@services/language-selector.service';
import { emptyString } from 'app/constants/global.constants';
import { makeGenericExtender } from 'app/functions/make-generic-extender.function';
import { INewSectionDialog, ISelects } from 'app/repository/models/method.models';
import { IArticleType, IServiceType } from 'app/repository/models/need.model';
import { CommonApiService } from 'app/use-cases/services/common-api/common-api.service';
import { UtilsService } from 'app/use-cases/services/utils/utils.service';

import { MDialogRef, M_DIALOG_DATA, M_DIALOG_REF } from '@mercadona/components/dialog';
import { MTranslateService } from '@mercadona/core/translate';

@Component({
  selector: 'app-dialog-new-section-method',
  templateUrl: './dialog-new-section-method.component.html',
  styleUrls: ['./dialog-new-section-method.component.scss']
})
export class DialogNewSectionMethodComponent implements AfterViewInit {
  newSectionForm!: FormGroup;
  classificationId: string = emptyString;
  optionsRoles = [
    {
      id: 0,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Lector'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Leitor'
        }
      ]
    },
    {
      id: 1,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Editor'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Editora'
        }
      ]
    }
  ];
  unsubscribe$ = new Subject<void>();
  articleTypesOptions: IArticleType[] = [];
  serviceTypesOptions: IServiceType[] = [];
  /**
   * Class constructor
   *
   * @param {MDialogRef} ref ref dialog
   * @param {object} data data
   * @param {string} data.classificationId string classification id
   * @param {FormBuilder} formBuilder form builder
   * @param {UtilsService} utilsService utils service
   * @param {LanguageSelectorService} languageSelectorService language service selector
   * @param {MTranslateService} mTranslateService translate service
   * @param {CommonApiService} commonApiService common api service
   * @param {Renderer2} renderer renderer
   * @param {Router} router router
   */
  constructor(
    @Inject(M_DIALOG_REF) public ref: MDialogRef,
    @Inject(M_DIALOG_DATA)
    public data: {
      classificationId: string;
    },
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public languageSelectorService: LanguageSelectorService,
    public mTranslateService: MTranslateService,
    private commonApiService: CommonApiService,
    public renderer: Renderer2,
    private router: Router
  ) {
    this.classificationId = data.classificationId;

    this.buildForm();
    this.addValueChanges();
    this.getGoodTypes();
    this.getServiceTypes();
    this.optionsRoles = this.optionsRoles.map((c: ISelects) => makeGenericExtender<ISelects>(c));
  }

  ngAfterViewInit(): void {
    this.newSectionForm.controls.goodType.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: GenericExtender<IArticleType>) => {
        this.changeHeight(value);
      });
  }

  buildForm() {
    this.newSectionForm = this.formBuilder.group({
      titleES: new FormControl<string | null>(null, [Validators.required]),
      shortDescriptionES: new FormControl<string | null>(null, []),
      titlePT: new FormControl<string | null>(null, []),
      shortDescriptionPT: new FormControl<string | null>(null, []),
      roles: new FormControl<number[] | null>(null, [Validators.required]),
      goodType: new FormControl<GenericExtender<IArticleType> | null>(null, []),
      serviceType: new FormControl<number[] | null>(null, [])
    });
  }

  addValueChanges() {
    this.newSectionForm.controls.goodType.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: GenericExtender<IArticleType>) => {
        const serviceTypeControl = this.newSectionForm.controls.serviceType;
        if (value.id === 2) {
          serviceTypeControl.setValidators([Validators.required]);
        } else {
          serviceTypeControl.setValue(null);
          serviceTypeControl.clearValidators();
        }
        serviceTypeControl.updateValueAndValidity();
      });
  }

  getGoodTypes() {
    this.utilsService.getGoodTypes().subscribe((articleTypes) => {
      this.articleTypesOptions = articleTypes;
    });
  }

  getServiceTypes() {
    this.utilsService.getServiceTypes().subscribe((serviceTypesOptions) => {
      this.serviceTypesOptions = serviceTypesOptions;
    });
  }

  createNewSection() {
    const valueData: INewSectionDialog = {
      classificationId: this.classificationId,
      names: [
        {
          localeLanguage: 'es-ES',
          title: this.newSectionForm.controls.titleES.value || emptyString,
          shortDescription: this.newSectionForm.controls.shortDescriptionES.value || emptyString
        },
        {
          localeLanguage: 'pt-PT',
          title: this.newSectionForm.controls.titlePT.value || emptyString,
          shortDescription: this.newSectionForm.controls.shortDescriptionPT.value || emptyString
        }
      ],
      roles: this.newSectionForm.controls.roles.value.map((role: { id: number }) => role.id) || [],
      goodType: this.newSectionForm.controls.goodType.value?.id || emptyString,
      serviceType: this.newSectionForm.controls.serviceType.value?.id || []
    };

    this.ref.close();
    this.router.navigate([`/new-section-method/${this.classificationId}`], { state: { body: valueData } });
  }

  changeHeight(value: GenericExtender<IArticleType> | null) {
    const heightDialog = !value || value.id === 1 ? '698px' : '795px';
    if (this.ref) {
      const dialogContainer = document.querySelector('.cdk-overlay-pane');
      if (dialogContainer) {
        this.renderer.setStyle(dialogContainer, 'height', heightDialog);
        this.renderer.setStyle(dialogContainer, 'min-height', heightDialog);
        this.renderer.setStyle(dialogContainer, 'max-height', heightDialog);
      }
    }
  }
}
