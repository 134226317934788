<m-dialog-card class="m-dialog-card-method" titleLabel="{{ 'method.new-section.dialog-subtitle' | mTranslate }}">
  <m-dialog-card-close-icon>
    <m-icon
      (click)="ref.close()"
      onKeyUp="ref.close()"
      iconName="bold/interface-essential/form-validation/close"></m-icon>
  </m-dialog-card-close-icon>
  <div class="d-flex flex-column w-100 mt-0 mb-0 content-dialog">
    <div
      [formGroup]="filtersMethod"
      *ngIf="displayAdminFilters"
      [ngClass]="{ 'd-flex flex-row w-100': displayAdminFilters }">
      <div class="flex-column me-1 d-flex flex-grow-1">
        <m-form-field label="{{ 'method.language' | mTranslate }}">
          <m-select
            #mSelectToTranslateLabelKey1
            app-translate-label-key
            [componentRef]="mSelectToTranslateLabelKey1"
            [translateLabelKeyFunction]="utils.mSelectTranslateLabelKey"
            [options]="optionsLanguage"
            formControlName="language"
            [labelKey]="languageSelectorService.bSubject.value"
            placeholder="{{ 'select-placeholder' | mTranslate }}"
            [allowClear]="true">
          </m-select>
        </m-form-field>
      </div>
      <div class="flex-column me-1 d-flex flex-grow-1">
        <m-form-field label="{{ 'method.new-section.permissions' | mTranslate }}">
          <m-select
            #mSelectToTranslateLabelKey1
            app-translate-label-key
            [componentRef]="mSelectToTranslateLabelKey1"
            [translateLabelKeyFunction]="utils.mSelectTranslateLabelKeyMultiple"
            [options]="optionsRoles"
            [search]="true"
            formControlName="roles"
            [multiple]="true"
            [allowClear]="true"
            [labelKey]="languageSelectorService.bSubject.value"
            placeholder="{{ 'select-placeholder' | mTranslate }}">
          </m-select>
        </m-form-field>
      </div>
      <div class="flex-column me-1 d-flex flex-grow-1">
        <m-form-field label="{{ 'method.new-section.product-type-filter' | mTranslate }}">
          <m-select
            #mSelectToTranslateLabelKey1
            app-translate-label-key
            [componentRef]="mSelectToTranslateLabelKey1"
            [translateLabelKeyFunction]="utils.mSelectTranslateLabelKey"
            [options]="articleTypesOptions"
            formControlName="goodType"
            [labelKey]="languageSelectorService.bSubject.value"
            placeholder="{{ 'select-placeholder' | mTranslate }}"
            [allowClear]="true">
          </m-select>
        </m-form-field>
      </div>
      <div
        class="flex-column me-1 d-flex flex-grow-1"
        [ngClass]="{
          'flex-column me-1 d-flex flex-grow-1': displayAdminFilters && filtersMethod.controls.goodType.value,
          'd-none': displayAdminFilters && filtersMethod.controls.goodType.value?.id !== 2
        }">
        <m-form-field label="{{ 'method.new-section.service-type' | mTranslate }}">
          <m-select
            #mSelectToTranslateLabelKey1
            app-translate-label-key
            [componentRef]="mSelectToTranslateLabelKey1"
            [translateLabelKeyFunction]="utils.mSelectTranslateLabelKey"
            [options]="serviceTypesOptions"
            formControlName="serviceType"
            [labelKey]="languageSelectorService.bSubject.value"
            placeholder="{{ 'select-placeholder' | mTranslate }}"
            [allowClear]="true">
          </m-select>
        </m-form-field>
      </div>
    </div>
    <div class="d-flex flex-row w-100">
      <div
        class="left-panel d-flex flex-column"
        [ngClass]="{
          'left-panel d-flex flex-column': true,
          'height-with-filters': displayAdminFilters,
          'height-not-filters': !displayAdminFilters
        }">
        <div class="list-sections d-flex flex-column">
          <m-list [isSelectable]="true">
            <m-list-item
              [description]="getTitleDescription(section.section.names, 'description')"
              *ngFor="let section of sections; let i = index; trackBy: utils.trackByFn"
              [selected]="section.selected"
              onkeyup="getInfoSection(section)"
              (click)="getInfoSection(section)">
              {{ getTitleDescription(section.section.names, 'name') }}
              <button
                aria-label="regular/arrows-diagrams/arrows/arrow-right-1"
                m-button
                m-list-item-action
                buttonType="icon">
                <m-icon iconName="regular/arrows-diagrams/arrows/arrow-right-1"></m-icon>
              </button>
            </m-list-item>
          </m-list>
        </div>
        <div
          [ngClass]="{
            'd-none': !displayAdminFilters,
            'mt-auto align-self-center edit-container': displayAdminFilters
          }">
          <button m-button iconSide="right" buttonType="ghost" color="main" (click)="editMethod()">
            {{ 'method.button-edit' | mTranslate }}
            <m-icon iconName="regular/interface-essential/edit/pencil-1"></m-icon>
          </button>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="divider mt-2 mb-2"></div>
      </div>
      <div
        class="right-panel p-1 d-flex flex-column"
        [ngClass]="{
          'right-panel p-1 d-flex flex-column': true,
          'height-with-filters': displayAdminFilters,
          'height-not-filters': !displayAdminFilters
        }">
        <div class="container-method" [innerHTML]="htmlSafe"></div>
      </div>
    </div>
  </div>
</m-dialog-card>
