import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EMPTY, Subject, catchError, debounceTime, of, tap } from 'rxjs';

import { GenericExtender } from '@interfaces/generic-extender.interface';
import { ISelectable } from '@interfaces/selectable.interface';
import { LanguageSelectorService } from '@services/language-selector.service';
import { emptyString } from 'app/constants/global.constants';
import { SNACK_DURATION_DEFAULT, SPINNER_OPTIONS } from 'app/constants/spinner.constants';
import { makeGenericExtender } from 'app/functions/make-generic-extender.function';
import { matchLocaleLanguage } from 'app/functions/match-locale-language.function';
import { translateTo } from 'app/functions/translate-to.function';
import {
  INamesDetailResponse,
  ISectionBody,
  ISectionBodyResponse,
  ISectionDetailResponse,
  ISectionsListResponse,
  ISelects
} from 'app/repository/models/method.models';
import { IArticleType, IServiceType } from 'app/repository/models/need.model';
import { MethodApiService } from 'app/use-cases/services/method-api/method-api.service';
import { UtilsService } from 'app/use-cases/services/utils/utils.service';

import { M_DIALOG_REF, MDialogRef, M_DIALOG_DATA } from '@mercadona/components/dialog';
import { MSnackbarService } from '@mercadona/components/snackbar';
import { MSpinnerGlobalService } from '@mercadona/components/spinner';
import { MTranslateService } from '@mercadona/core/translate';

@Component({
  selector: 'app-dialog-method',
  templateUrl: './dialog-method.component.html',
  styleUrls: ['./dialog-method.component.scss']
})
export class DialogMethodComponent {
  classificationId: string = emptyString;
  htmlSafe!: SafeHtml;
  sectionDetail!: ISectionDetailResponse;
  sections!: ISelectable<ISectionBodyResponse>[];
  $translater = translateTo();
  filtersMethod!: FormGroup;
  displayAdminFilters: boolean;
  optionsLanguage = [
    {
      id: 0,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Español'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Espanhol'
        }
      ]
    },
    {
      id: 1,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Portugués'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Português'
        }
      ]
    }
  ];
  optionsRoles = [
    {
      id: 0,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Lector'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Leitor'
        }
      ]
    },
    {
      id: 1,
      names: [
        {
          localeLanguage: 'es-ES',
          name: 'Editor'
        },
        {
          localeLanguage: 'pt-PT',
          name: 'Editora'
        }
      ]
    }
  ];
  articleTypesOptions: IArticleType[] = [];
  serviceTypesOptions: IServiceType[] = [];
  activeLanguage: string = 'es-ES';
  unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(M_DIALOG_REF) public ref: MDialogRef,
    @Inject(M_DIALOG_DATA)
    public data: {
      displayAdminFilters: boolean;
      classificationId: string;
      sectionList: ISectionsListResponse;
      sectionDetail: ISectionDetailResponse;
    },
    private sanitizer: DomSanitizer,
    public utils: UtilsService,
    public languageSelectorService: LanguageSelectorService,
    private methodApiService: MethodApiService,
    private mSpinnerGlobalService: MSpinnerGlobalService,
    private mSnackbarService: MSnackbarService,
    public mTranslateService: MTranslateService,
    public formBuilder: FormBuilder
  ) {
    this.sections =
      (data.sectionList.sections as ISelectable<ISectionBodyResponse>[])?.map((section, index) => ({
        ...section,
        selected: index === 0
      })) || [];
    this.displayAdminFilters = data.displayAdminFilters;
    this.sectionDetail = data.sectionDetail;
    this.classificationId = data.classificationId;
    const search = this.displayAdminFilters
      ? this.sectionDetail.names.find((item) => item.localeLanguage === this.activeLanguage)
      : this.sectionDetail.names.find((item) =>
          matchLocaleLanguage(item.localeLanguage, this.languageSelectorService.bSubject.value)
        );
    const names = search?.html || this.sectionDetail.names.find((item) => item.localeLanguage === 'es-ES')?.html;

    this.securityHtml(names ?? '');
    if (this.displayAdminFilters) {
      this.getInfoSelects();
      this.buildFormFilters();
      this.onFilterFormValueChange();
    }
  }

  getInfoSelects() {
    this.getGoodTypes();
    this.getServiceTypes();
    this.optionsLanguage = this.optionsLanguage.map((c: ISelects) => makeGenericExtender<ISelects>(c));
    this.optionsRoles = this.optionsRoles.map((c: ISelects) => makeGenericExtender<ISelects>(c));
  }

  getGoodTypes() {
    this.utils.getGoodTypes().subscribe((articleTypes) => {
      this.articleTypesOptions = articleTypes;
    });
  }

  getServiceTypes() {
    this.utils.getServiceTypes().subscribe((serviceTypesOptions) => {
      this.serviceTypesOptions = serviceTypesOptions;
    });
  }

  onFilterFormValueChange() {
    this.filtersMethod.valueChanges
      .pipe(
        debounceTime(500),
        tap((v) => {
          if (!v.goodType && this.filtersMethod.controls.serviceType.value) {
            this.filtersMethod.controls.serviceType.setValue(null, { emitEvent: false });
          }
          this.setActiveLanguage(this.filtersMethod.controls.language.value);
          this.onFilterFormValueChangeCallback();
        })
      )
      .subscribe();
  }

  setActiveLanguage(value: ISelects | null) {
    const languageMap = ['', 'pt-PT'];
    this.activeLanguage = value && languageMap[value.id] ? languageMap[value.id] : 'es-ES';
  }

  onFilterFormValueChangeCallback() {
    const body = {
      classificationId: this.classificationId,
      roles: this.filtersMethod.controls.roles.value?.map((role: { id: number }) => role.id) || [],
      goodType: this.filtersMethod.controls.goodType.value?.id || emptyString,
      serviceType: this.filtersMethod.controls.serviceType.value
        ? [this.filtersMethod.controls.serviceType.value.id]
        : []
    } as ISectionBody;

    this.getMethodFilter(body);
  }

  getMethodFilter(body: ISectionBody) {
    const spinnerRef = this.mSpinnerGlobalService.create(SPINNER_OPTIONS);
    this.utils
      .getSectionMethod(body)
      .pipe(
        tap(
          (result: {
            hasSections: boolean;
            sectionList?: ISectionsListResponse;
            sectionDetail?: ISectionDetailResponse;
          }) => {
            if (result.hasSections) {
              this.sections =
                (result.sectionList?.sections as ISelectable<ISectionBodyResponse>[])?.map((section) => ({
                  ...section,
                  selected: false
                })) || [];
              if (this.sections.length > 0) {
                const active = result.sectionDetail?.names.find((item) => item.localeLanguage === this.activeLanguage);
                this.securityHtml(active?.html ?? '');
                this.sections[0].selected = true;
              }
            } else {
              this.sections = [];
            }
            spinnerRef.close();
          }
        ),
        catchError(() => {
          this.mSnackbarService.create({
            message: this.mTranslateService.translate(`method.operations.get.error`),
            action: this.mTranslateService.translate(`close`),
            duration: SNACK_DURATION_DEFAULT
          });
          spinnerRef.close();
          return of(EMPTY);
        })
      )
      .subscribe();
  }

  buildFormFilters() {
    this.filtersMethod = this.formBuilder.group({
      language: new FormControl<number | null>(null, []),
      roles: new FormControl<number[] | null>(null, []),
      goodType: new FormControl<GenericExtender<IArticleType> | null>(null, []),
      serviceType: new FormControl<number[] | null>(null, [])
    });
  }

  securityHtml(html: string) {
    this.htmlSafe = this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /*securityHtml(html: string) {
    this.htmlSafe = this.sanitizeHtml(html);
  }

  sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(1, html) || '';
  }*/

  getTitleDescription(namesSection: INamesDetailResponse[], type: 'name' | 'description'): string {
    const search = this.displayAdminFilters
      ? namesSection.find((item) => item.localeLanguage === this.activeLanguage)
      : namesSection.find((item) =>
          matchLocaleLanguage(item.localeLanguage, this.languageSelectorService.bSubject.value)
        );
    const names = search || namesSection.find((item) => item.localeLanguage === 'es-ES');

    if (type === 'name') {
      return names?.title || namesSection.find((item) => item.localeLanguage === 'es-ES')?.title || '';
    }
    return (
      names?.shortDescription || namesSection.find((item) => item.localeLanguage === 'es-ES')?.shortDescription || ''
    );
  }

  getInfoSection(section: ISelectable<ISectionBodyResponse>) {
    this.filterSelectedSection(section);
    const spinnerRef = this.mSpinnerGlobalService.create(SPINNER_OPTIONS);
    this.methodApiService
      .getInfoSection(section.section.id.toString())
      .pipe(
        tap((detailSection: ISectionDetailResponse) => {
          const search = this.displayAdminFilters
            ? detailSection.names.find((item) => item.localeLanguage === this.activeLanguage)
            : detailSection.names.find((item) =>
                matchLocaleLanguage(item.localeLanguage, this.languageSelectorService.bSubject.value)
              );
          const names = search?.html || detailSection.names.find((item) => item.localeLanguage === 'es-ES')?.html;

          this.securityHtml(names ?? '');
          spinnerRef.close();
        }),
        catchError(() => {
          this.mSnackbarService.create({
            message: this.mTranslateService.translate(`method.operations.get-section.error`),
            action: this.mTranslateService.translate(`close`),
            duration: SNACK_DURATION_DEFAULT
          });
          spinnerRef.close();
          return of(EMPTY);
        })
      )
      .subscribe();
  }

  filterSelectedSection(sectionSelected: ISelectable<ISectionBodyResponse>) {
    this.sections.forEach((section) => (section.selected = false));
    sectionSelected.selected = !sectionSelected.selected;
  }

  editMethod() {
    this.ref.close();
    this.utils.editSectionMethod(this.classificationId);
  }
}
