import { FormGroup, ValidatorFn } from '@angular/forms';

import { DataService } from '@pages/new-need/components/data/services/data.service';
import { ProductImplementationService } from '@pages/new-need/components/product-implementation/services/product-implementation.service';
import { NewNeedPageService } from '@pages/new-need/services/new-need-page.service';
import {
  DataService as PurchasingManagerDataService,
  addHasChangedFormGroupValidator
} from '@pages/product/components/relay-race/purchasing-manager/components/purchasing-data/services/data.service';
import { DataService as ProviderDataService } from '@pages/product/components/relay-race/supplier-race/components/supplier-data/services/data.service';
import { CRUDOptionsType } from 'app/types/crud-operation.types';

/**
 *
 * @param {FormGroup} formGroup form group
 */
export function removeValidatorsAndExtraFields(formGroup: FormGroup) {
  delete (formGroup as typeof formGroup & { projectOldValue?: FormGroup }).projectOldValue;
  delete (formGroup as typeof formGroup & { hasChanged?: boolean }).hasChanged;
  let validators = (formGroup as typeof formGroup & { _rawValidators: ValidatorFn[] })._rawValidators;
  if (!validators) {
    validators = [];
  }
  const validator = validators.at(0) as ValidatorFn;
  if (validator) {
    formGroup.removeValidators(validator);
  }
}

/**
 *
 * @param {FormGroup} formGroup form group
 * @param {object} config config
 * @param {CRUDOptionsType} config.operation operation
 * @param {Record<string, boolean> | undefined} config.disabledFormControlMap disabled form control map
 * @param {boolean} markAsInvalid mark as invalid
 * @param {boolean} afterPostSaveOperation after post save operation
 * @param {object} oldValue old value
 * @param {object} overwriteFormGroupValues over write form group values
 */
export function patchOldFormValueAndAddValidatorAgain(
  this:
    | NewNeedPageService
    | DataService
    | ProductImplementationService
    | PurchasingManagerDataService
    | ProviderDataService,
  formGroup: FormGroup,
  config?: {
    operation: CRUDOptionsType;
    disabledFormControlMap: Record<string, boolean> | undefined;
  },
  markAsInvalid?: boolean,
  afterPostSaveOperation?: boolean,
  oldValue?: object,
  overwriteFormGroupValues?: object
) {
  let oldFormGroup;
  if (config) {
    oldFormGroup = this.buildForm({
      operation: config.operation,
      disabledFormControlMap: config.disabledFormControlMap
    });
  } else {
    oldFormGroup = this.buildForm();
  }
  let valueToBePatched;
  if (afterPostSaveOperation === undefined || afterPostSaveOperation === true) {
    valueToBePatched = {
      ...formGroup.getRawValue()
    };
  } else {
    valueToBePatched = {
      ...(oldValue ?? {}),
      ...(overwriteFormGroupValues ?? {})
    };
  }
  oldFormGroup.patchValue(valueToBePatched);
  addHasChangedFormGroupValidator(formGroup, oldFormGroup, markAsInvalid === undefined ? false : markAsInvalid);
  this.nextMappedValueForm(formGroup.getRawValue(), formGroup.valid);
  formGroup.updateValueAndValidity();
}
