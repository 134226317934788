export const emptyString = '';
export const emptyArray = [];
export const unsided = 'unsided';
export const sided = 'sided';
export const hidden = 'hidden-go';
export const showable = 'showable-go';

const classesCamelCasePre = {
  third: true,
  default: false
};

/**
 *
 * @param {boolean} v1 value boolean
 * @param {boolean} v2 value boolean
 * @returns {object} return
 */
export function classesCamelCase(v1: boolean, v2: boolean) {
  return Object.entries(classesCamelCasePre).reduce((acc, [key, value]) => {
    if (value) {
      value = v1;
    } else {
      value = v2;
    }
    acc[key.replace('third', 'third-column-overflow-need-tab').toLowerCase()] = value;
    acc[key.replace('default', 'default-show-new-need-button').toLowerCase()] = value;
    return acc;
  }, {} as Record<string, boolean>);
}
