import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { IChartsResponse, ILastMovementsResponse, INeedsChart } from 'app/repository/models/home.models';
import { HomeService } from 'app/repository/services/home/home.service';

import {
  GetProductListRequestBodyType,
  HomeApiServiceAbstraction,
  IHomeApiService
} from '../home-api/home-api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeTaxApiService extends HomeApiServiceAbstraction implements IHomeApiService {
  constructor(homeService: HomeService) {
    super(homeService);
  }

  getLastMovements(pageNumber: number, pageSize: number): Observable<ILastMovementsResponse> {
    return this.homeService.getTaxLastMovements(pageNumber, pageSize);
  }

  getChartsHome(): Observable<IChartsResponse> {
    return this.homeService.getTaxCharts().pipe(
      map((res) => ({
        data: [
          {
            chartType: 'T' as string,
            needs: undefined as unknown as INeedsChart,
            products: {
              done: res.data.covered,
              pending: res.data.pending,
              donePercentage: res.data.coveredPercentage
            }
          }
        ]
      }))
    );
  }

  getProductsList(body: GetProductListRequestBodyType) {
    return this.homeService.getTaxVariableArticles(
      body.search,
      body.pageNumber,
      body.pageSize,
      body.statusId,
      body.missingTaxInfo
    );
  }
}
