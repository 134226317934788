import { MSpinnerGlobalOptions } from '@mercadona/components/spinner';

export const SPINNER_OPTIONS = {
  size: 'default',
  color: 'main'
} as MSpinnerGlobalOptions;

export const SNACK_DURATION_SHORT: number = 2000;
export const SNACK_DURATION_DEFAULT: number = 3500;
export const SNACK_DURATION_LONG: number = 5000;
