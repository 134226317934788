<m-dialog-card
  subtitle="{{ 'method.new-section.dialog-subtitle' | mTranslate }}"
  titleLabel="{{ 'method.new-section.dialog-title' | mTranslate }}"
  [formGroup]="newSectionForm">
  <m-dialog-card-close-icon>
    <m-icon
      (click)="ref.close()"
      onKeyUp="ref.close()"
      iconName="bold/interface-essential/form-validation/close"></m-icon>
  </m-dialog-card-close-icon>
  <div class="d-flex flex-column w-100 mt-0 mb-0 content-dialog">
    <m-tab-group>
      <m-tab titleLabel="{{ 'selected-language.es_ES' | mTranslate }}">
        <div class="mt-2 overflow-hidden">
          <m-form-field label="{{ 'method.new-section.title' | mTranslate }}">
            <input
              formControlName="titleES"
              m-input
              position="after"
              text-align="left"
              placeholder="{{ 'write-placeholder' | mTranslate }}" />
          </m-form-field>
          <m-form-field label="{{ 'method.new-section.short-description' | mTranslate }}" [hiddenHint]="true">
            <input
              formControlName="shortDescriptionES"
              m-input
              position="after"
              text-align="left"
              placeholder="{{ 'write-placeholder' | mTranslate }}" />
          </m-form-field>
        </div>
      </m-tab>
      <m-tab titleLabel="{{ 'selected-language.pt_PT' | mTranslate }}">
        <div class="mt-2 overflow-hidden">
          <m-form-field label="{{ 'method.new-section.title' | mTranslate }}">
            <input
              formControlName="titlePT"
              m-input
              position="after"
              text-align="left"
              placeholder="{{ 'write-placeholder' | mTranslate }}" />
          </m-form-field>
          <m-form-field label="{{ 'method.new-section.short-description' | mTranslate }}" [hiddenHint]="true">
            <input
              formControlName="shortDescriptionPT"
              m-input
              position="after"
              text-align="left"
              placeholder="{{ 'write-placeholder' | mTranslate }}" />
          </m-form-field></div
      ></m-tab>
    </m-tab-group>
    <div>
      <div class="divider mt-2 mb-2"></div>
    </div>
    <m-form-field label="{{ 'method.new-section.permissions' | mTranslate }}">
      <m-select
        #mSelectToTranslateLabelKey1
        app-translate-label-key
        [componentRef]="mSelectToTranslateLabelKey1"
        [translateLabelKeyFunction]="utilsService.mSelectTranslateLabelKeyMultiple"
        [options]="optionsRoles"
        [search]="true"
        formControlName="roles"
        [multiple]="true"
        [allowClear]="true"
        [labelKey]="languageSelectorService.bSubject.value"
        placeholder="{{ 'select-placeholder' | mTranslate }}">
      </m-select>
    </m-form-field>
    <m-form-field label="{{ 'method.new-section.product-type' | mTranslate }}">
      <m-select
        #mSelectToTranslateLabelKey2
        app-translate-label-key
        [componentRef]="mSelectToTranslateLabelKey2"
        [translateLabelKeyFunction]="utilsService.mSelectTranslateLabelKey"
        [options]="articleTypesOptions"
        appEnableControl
        formControlName="goodType"
        [labelKey]="languageSelectorService.bSubject.value"
        placeholder="{{ 'select-placeholder' | mTranslate }}"
        [allowClear]="true">
      </m-select>
    </m-form-field>
    <m-form-field
      label="{{ 'method.new-section.service-type' | mTranslate }}"
      *ngIf="newSectionForm.controls.goodType.value?.id.toString() === '2'">
      <m-select
        #mSelectToTranslateLabelKey3
        app-translate-label-key
        [componentRef]="mSelectToTranslateLabelKey3"
        [translateLabelKeyFunction]="utilsService.mSelectTranslateLabelKey"
        [options]="serviceTypesOptions"
        appEnableControl
        formControlName="serviceType"
        [labelKey]="languageSelectorService.bSubject.value"
        placeholder="{{ 'select-placeholder' | mTranslate }}"
        [allowClear]="true">
      </m-select>
    </m-form-field>
  </div>
  <m-dialog-card-actions>
    <button class="me-2" m-button (click)="ref.close()" buttonType="ghost" color="base">
      {{ 'cancel-action' | mTranslate }}
    </button>
    <button m-button (click)="createNewSection()" buttonType="flat" color="main" [disabled]="!newSectionForm.valid">
      {{ 'method.new-section.button-create' | mTranslate }}
    </button>
  </m-dialog-card-actions>
</m-dialog-card>
